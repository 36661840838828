import { render, staticRenderFns } from "./EditUnverifiedArticleModal.vue?vue&type=template&id=4fd36cf3&scoped=true"
import script from "./EditUnverifiedArticleModal.vue?vue&type=script&lang=js"
export * from "./EditUnverifiedArticleModal.vue?vue&type=script&lang=js"
import style0 from "./EditUnverifiedArticleModal.vue?vue&type=style&index=0&id=4fd36cf3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd36cf3",
  null
  
)

export default component.exports