// export const API_URL = "https://supplymeapp.herokuapp.com";

// export const API_URL = "https://supplymeappdev.herokuapp.com/";
// export const API_URL = "https://supplyme.onrender.com";
//auth
export const LOGIN_URL = `/login`;
export const LOGOUT_URL = `/logout`;
export const SIGNUP_URL = `/register`;
export const REFRESH_TOKEN_URL = `/refresh_token`;
export const CHECK_EMAIL_URL = `/checkEmail`;

//user profile
export const USER_PROFILE_URL = `/users/profile`;
export const UPDATE_USER_PROFILE_URL = `/users/profile/edit`;
export const CHANGE_USER_PASSWORD_URL = `/users/profile/changePassword`;

//orders
export const getAllOrdersURL = (departmentId, page, pageSize, status) =>
  `/orders/all/${departmentId}?page=${page}&pageSize=${pageSize}&status=${status}`;
export const getAllOrdersForUserAdminURL = (venueId, page, pageSize, status) =>
  `/allVenueOrders/${venueId}?page=${page}&pageSize=${pageSize}&status=${status}`;
export const createOrderURL = (departmentID, supplierID) =>
  `/orders/${departmentID}/create/${supplierID}`;
export const getOrderURL = (id) => `/orders/order/${id}`;
export const updateOrderURL = (id) => `/orders/${id}/updateOrderItems`;
export const sendOrderURL = (id) =>
  `/orders/updateStatus/sendOrder?orderId=${id}`;
export const completeOrderURL = (id) =>
  `/orders/updateStatus/complete?orderId=${id}`;
export const deleteOrderURL = (id) => `/orders/deleteOrder/${id}`;
export const createCustomOrderURL = (departmentId) =>
  `/orders/custom/${departmentId}/create`;
export const updateCustomOrderURL = (id) =>
  `/orders/custom/${id}/updateOrderItems`;
export const getCustomOrderURL = (id) => `/orders/custom/order/${id}`;
export const deleteCustomProductOrderURL = (id) =>
  `/orders/custom/deleteProduct/${id}`;
export const deleteCustomOrderURL = (id) => `/orders/custom/deleteOrder/${id}`;
export const completeCustomOrderURL = (id) =>
  `/orders/custom/updateStatus/complete?orderId=${id}`;
export const sendCustomOrderURL = (id) =>
  `/orders/custom/updateStatus/sendOrder?orderId=${id}`;
//newest APIs for current orders for regular and custom
export const getAllRegularOrdersURL = (
  departmentId,
  page,
  pageSize,
  status,
  supplierId
) => {
  return supplierId
    ? `/orders/all/${departmentId}?page=${page}&pageSize=${pageSize}&status=${status}&supplierId=${supplierId}`
    : `/orders/all/${departmentId}?page=${page}&pageSize=${pageSize}&status=${status}`;
};
export const getAllCustomOrdersURL = (departmentId, page, pageSize, status) =>
  `/orders/custom/all/${departmentId}?page=${page}&pageSize=${pageSize}&status=${status}`;

//supplier
export const ADD_SUPPLIER_URL = `/supplier/add`;
export const uploadSupplierData = (id) => `/supplier/uploadSupplierData/${id}`;
export const addSupplierContact = (id) => `/supplier/${id}/addContact`;
export const getAllOrdersVenueURL = (venueId) =>
  `/orders/allVenueOrders/${venueId}`;
export const deleteContactSupplierURL = (id) => `/supplier/deleteContact/${id}`;
export const editContactSupplierURL = (id) => `/supplier/editContact/${id}`;
export const editSupplierDataURL = (supplierId) =>
  `/supplier/${supplierId}/edit`;
export const addSupplierNotesURL = (supplierId) =>
  `/supplier/${supplierId}/addSupplierNotes`;
export const getSupplierContactsURL = (supplierId, venueId) =>
  `/supplier/supplierContacts/${supplierId}/${venueId}`;
export const getSupplierNoteURL = (supplierId, venueId) =>
  `/supplier/supplierNote/${supplierId}/${venueId}`;
export const getSuppliersForDepartmentURL = (departmentId) =>
  `/venue/department/getSuppliers/${departmentId}`;
export const REQUEST_NEW_SUPPLIER_URL = `/supplier/requestSupplier`;

///articles
export const getArticlesDepartment = (
  id,
  pageNum,
  categoryId,
  searchQuery,
  departmentId,
  onlyFavorite
) => {
  return categoryId
    ? `/articles/products/${departmentId}/${id}?page=${pageNum}&category=${categoryId}&searchQuery=${
        searchQuery || ""
      }&onlyFavorite=${onlyFavorite}`
    : `/articles/products/${departmentId}/${id}?page=${pageNum}&searchQuery=${
        searchQuery || ""
      }&onlyFavorite=${onlyFavorite}`;
};
export const getArticlesSupplierURL = (
  departmentId,
  supplierId,
  pageNum,
  categoryId,
  searchQuery,
  pageSize,
  onlyFavorite
) => {
  return categoryId
    ? `/articles/products/${departmentId}/${supplierId}?page=${pageNum}&category=${categoryId}&searchQuery=${
        searchQuery || ""
      }&pageSize=${pageSize}&onlyFavorite=${onlyFavorite}`
    : `/articles/products/${departmentId}/${supplierId}?page=${pageNum}&searchQuery=${
        searchQuery || ""
      }&pageSize=${pageSize}&onlyFavorite=${onlyFavorite}`;
};
export const manualAddArticleURL = (departmentId) =>
  `/articles/${departmentId}/manualAdd`;
// export const getArticlesSupplier = (id) => `/articles/allproducts/${id}`;
export const editProductURL = (departmentId) =>
  `/articles/${departmentId}/product/edit`;
export const supplierArticlesCategoriesURL = (supplierId) =>
  `/articles/categories/${supplierId}`;
export const toggleFavoriteArticleURL = (departmentId, articleId) =>
  `/articles/${departmentId}/${articleId}/toggleFavorite`;
export const toggleCustomArticleFavoriteURL = (departmentId, articleId) =>
  `/articles/custom/${departmentId}/${articleId}/toggleFavorite`;
export const getAllCustomArticlesURL = (
  departmentId,
  pageNum,
  searchQuery,
  pageSize,
  onlyFavorite,
  categoryName
) => {
  return categoryName
    ? `/articles/custom/${departmentId}?page=${pageNum}&pageSize=${pageSize}&searchQuery=${searchQuery}&onlyFavorite=${onlyFavorite}&category=${categoryName}`
    : `/articles/custom/${departmentId}?page=${pageNum}&pageSize=${pageSize}&searchQuery=${searchQuery}&onlyFavorite=${onlyFavorite}`;
};
export const deleteCustomArticleURL = (departmentId, articleId) =>
  `/articles/custom/delete/${departmentId}/${articleId}`;
export const editCustomArticleURL = (departmentId, articleId) =>
  `/articles/custom/edit/${departmentId}/${articleId}`;
export const REQUEST_NEW_ARTICLE = `/articles/requestNewArticle`;
export const articleSettingsURL = (departmentId, articleId) =>
  `/articles/products/articleSetting/${departmentId}/${articleId}`;
export const articleCustomSettingsURL = (departmentId, articleId) =>
  `/articles/products/custom/articleSetting/${departmentId}/${articleId}`;

//venue
export const REGISTER_VENUE_URL = `/venue/add`;
export const GET_ALL_CATEGORIES_URL = `/venue/categories`;
export const checkPibExistsUrl = (pib) => `/venue/exists/${pib}`;
export const requestVenueAccessURL = (pib) => `/venue/requestAccess/${pib}`;
export const venueApproveUserURL = (id) => `/venue/approve/${id}`;
export const makeAdminURL = (id) => `/venue/makeAdmin/${id}`;

export const GET_VENUE_DEPARTMENTS_URL = "/venue/getDepartments";
export const GET_VENUE_SUPPLIERS_URL = (id) => `/venue/getSuppliers/${id}`;

//admin
export const GET_ALL_RESTAURANTS_URL = "/admin/venues";
export const GET_ALL_SUPPLIERS_URL = "/admin/suppliers";
export const linkVenueSupplierURL = (venueID, supplierID) =>
  `/admin/linkVenueAndSupplier/${venueID}/${supplierID}`;
export const unlinkVenueSupplierURL = (venueID, supplierID) =>
  `/admin/unlinkVenueAnSupplier/${venueID}/${supplierID}`;
export const GET_ALL_USERS_URL = "/admin/users";
export const getSingleRestaurant = (id) => `/admin/venue/${id}`;
export const UNNAPROVED_USERS_URL = "/admin/unnaprovedUsers";
export const getAlUsersVenue = (id) => `/admin/users/${id}`;
export const toggleVenueStatusURL = (id) => `/admin/venue/${id}/toggle`;
export const disableUserURL = (id) => `/admin/users/${id}/disable`;
export const disableEmailNotificationsForUserURL = (id) =>
  `/admin/users/${id}/disableNotifications`;
export const getAllArticlesSupplierAdminURL = (
  supplierId,
  pageNum,
  pageSize,
  searchQuery,
  categoryId
) => {
  return categoryId
    ? `/admin/articles/${supplierId}?page=${pageNum}&pageSize=${pageSize}&searchQuery=${searchQuery}&categoryId=${categoryId}`
    : `/admin/articles/${supplierId}?page=${pageNum}&pageSize=${pageSize}&searchQuery=${searchQuery}`;
};
export const createOrUpdateArticleURL = (supplierId) =>
  `/admin/article/createOrUpdate/${supplierId}`;
export const deleteArticleURL = (articleId) =>
  `/admin/article/delete/${articleId}`;
export const removeAdminURL = (id) => `admin/users/${id}/removeAdmin`;
export const manuallyVerifyEmail = (email) => `admin/users/${email}/verify`;
export const toggleUserCanSendOrdersURL = (userId) => `admin/users/${userId}/toggleCanSendOrder`

//admin - stations
export const createStationURL = (venueID) =>
  `/admin/venue/${venueID}/addDepartment`;
export const getAllDepartmentsURL = (venueId) =>
  `/admin/departments/${venueId}`;
export const linkUserAndDepartment = (userId, departmentId) =>
  `/admin/linkDepartment/${userId}/${departmentId}`;
export const unlinkUserAndDepartment = (userId, departmentId) =>
  `/admin/unlinkDepartment/${userId}/${departmentId}`;
export const editDepartmentURL = (departmentId) =>
  `/venue/department/${departmentId}`;
export const deleteDepartmentURL = (departmentId) =>
  `/venue/department/${departmentId}`;
export const linkSupplierAndDepartmentURL = (departmentId, supplierId) =>
  `/admin/linkDepartmentAndSupplier/${departmentId}/${supplierId}`;
export const unLinkSupplierAndDepartmentURL = (departmentId, supplierId) =>
  `/admin/unlinkDepartmentAndSupplier/${departmentId}/${supplierId}`;
export const getAllArticleCategoriesURL = () => `/admin/categories`;

//invites
export const INVITE_USER_URL = "/invite/invite-user";
export const ACCEPT_INVITE_RESTAURANT_URL = "/invite/acceptInvite";
export const SIGNUP_URL_INVITE = "/register-invite";

//verify
export const requestResetPasswordUrl = (email) =>
  `/verify/requestResetPassword?email=${email}`;
export const REQUEST_CHANGE_PASSWORD_URL = "/verify/resetPassword";
export const verifyUserSuperAdminURL = (venueId, userId) =>
  `/verify/verifySuperAdmin/${venueId}/${userId}`;
export const verifyEmailURL = (token) =>
  `/verify/email?activationToken=${token}`;
export const resendVerifyEmailURL = "/verify/resendVerifyEmail";

//subscriptions
export const getSubscriptionURL = (venueId) =>
  `/subscription/getSubscription/${venueId}`;
export const SET_SUBSCRIPTION_PLAN = "/subscription/setSubscription";
export const SEND_PLAN_INQUIRY_URL = "/subscription/sendPlanInquiry";
export const SEND_ADDITIONAL_STATION_INQUIRY_URL =
  "/subscription/sendNewStationInquiry";

//reports
export const getReportDataURL = (
  departmentId,
  fromDate,
  toDate,
  searchQuery,
  pageSize,
  page
) => {
  return searchQuery
    ? `/analytics/orders?departmentId=${departmentId}&fromDate=${fromDate}&toDate=${toDate}&searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page}`
    : `/analytics/orders?departmentId=${departmentId}&fromDate=${fromDate}&toDate=${toDate}&pageSize=${pageSize}&page=${page}`;
};
export const getReportDataVenueURL = (
  venueId,
  fromDate,
  toDate,
  searchQuery,
  pageSize,
  page
) => {
  return searchQuery
    ? `/analytics/venue/orders?venueId=${venueId}&fromDate=${fromDate}&toDate=${toDate}&searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page}`
    : `/analytics/venue/orders?venueId=${venueId}&fromDate=${fromDate}&toDate=${toDate}&pageSize=${pageSize}&page=${page}`;
};
export const getReportDataCategoryURL = (
  departmentId,
  fromDate,
  toDate,
  searchQuery,
  pageSize,
  page,
  categoryId
) => {
  return searchQuery
    ? `/analytics/venue/ordersPerCategory?departmentId=${departmentId}&fromDate=${fromDate}&toDate=${toDate}&searchQuery=${searchQuery}&pageSize=${pageSize}&page=${page}&categoryId=${categoryId}`
    : `/analytics/venue/ordersPerCategory?departmentId=${departmentId}&fromDate=${fromDate}&toDate=${toDate}&pageSize=${pageSize}&page=${page}&categoryId=${categoryId}`;
};

//unverified supplier
export const createUnverifiedSupplierURL = () =>
  "/venue/unverified/supplier/add";
export const getUnverifiedSuppliersURL = () => "/venue/unverified/supplier";
export const getUnverifiedSuppliersWithDepartmentsURL = () =>
  "/venue/unverified/supplier/departments";
export const linkUnverifiedSupplierURL = (supplierId, departmentId) =>
  `/venue/unverified/supplier/link?supplierId=${supplierId}&departmentId=${departmentId}`;
export const unlinkUnverifiedSupplierURL = (supplierId, departmentId) =>
  `/venue/unverified/supplier/unlink?supplierId=${supplierId}&departmentId=${departmentId}`;
export const deleteSupplierURL = (supplierId) =>
  `/venue/unverified/supplier?supplierId=${supplierId}`;
export const createArticleForSupplierURL = (supplierId) =>
  `/venue/unverified/supplier/article?supplierId=${supplierId}`;
export const updateArticleForSupplierURL = (supplierId) =>
  `/venue/unverified/supplier/article?supplierId=${supplierId}`;
export const getAllUnverifiedArticleCategoriesURL = () =>
  `/venue/unverified/supplier/categories`;
export const FINISH_ONBOARDING_URL = "/users/finishOnboarding";
export const deleteSupplierArticleURL = (articleId) =>
  `/venue/unverified/supplier/article?articleId=${articleId}`;
export const updateSupplierURL = () => `/venue/unverified/supplier/update`;
