var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wizard-step-three-assign-suppliers pt-2 pb-4"},[_c('v-expansion-panels',{attrs:{"focusable":""}},_vm._l((_vm.allDepartments),function(departmentWithSupplier){return _c('v-expansion-panel',{key:departmentWithSupplier.department &&
        departmentWithSupplier.department.id},[_c('v-expansion-panel-header',[_vm._v(_vm._s(departmentWithSupplier.department.name))]),_c('v-expansion-panel-content',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"multiple":""}},[_vm._l((_vm.allSuppliers),function(supplier,index){return [_c('v-list-item',{key:supplier.name,class:{
                      'supplier-selected': _vm.isSupplierSelected(
                        supplier.id,
                        departmentWithSupplier
                      ),
                      'supplier-unselected': !_vm.isSupplierSelected(
                        supplier.id,
                        departmentWithSupplier
                      ),
                    },on:{"change":function($event){return _vm.onSupplierSelected(
                        supplier.id,
                        departmentWithSupplier.department.id
                      )}}},[[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(supplier.name)}})],1),_c('v-list-item-action',[(
                            !_vm.isSupplierSelected(
                              supplier.id,
                              departmentWithSupplier
                            )
                          )?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-star-outline ")]):_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v(" mdi-star ")])],1)]],2),(index < supplier.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }