var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"profile-icon-margins",attrs:{"justify":"space-around"}},[_c('v-menu',{attrs:{"rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('div',_vm._g(_vm._b({staticClass:"profile-nav-img-wrap"},'div',attrs,false),on),[_c('v-img',{attrs:{"max-width":"16","src":require('../assets/img/ProfileNav.svg')}})],1)]}}])},[_c('v-list',{staticClass:"profile-menu-list"},_vm._l((_vm.items),function(item,index){return (item.show)?_c('v-list-item',{key:item.id,class:[
          {
            borderTop: index === _vm.items.length - 5,
            disabled: _vm.isPendingInvitation && item.id !== 7,
          },
          { 'active-link': _vm.isActive(item) },
        ],attrs:{"link":""}},[_c('v-list-item-content',[(!item.isLink)?_c('router-link',{staticClass:"profile-nav-link",attrs:{"to":_vm.isPendingInvitation && item.path !== '/logout' ? '#' : item.path,"exact-active-class":"active-link"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('a',{staticClass:"profile-nav-link",attrs:{"href":item.path,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1):_vm._e()}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }