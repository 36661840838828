import { render, staticRenderFns } from "./ReceiveOrderDialog.vue?vue&type=template&id=2f0b860e&scoped=true"
import script from "./ReceiveOrderDialog.vue?vue&type=script&lang=js"
export * from "./ReceiveOrderDialog.vue?vue&type=script&lang=js"
import style0 from "./ReceiveOrderDialog.vue?vue&type=style&index=0&id=2f0b860e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0b860e",
  null
  
)

export default component.exports